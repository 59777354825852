import React, { useState, useEffect, useContext } from 'react';
import { Row, Modal } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { trackEvent } from 'services/externals/mixpanel';
import AppContext from 'context/Context';
import PropTypes from 'prop-types';
import DynamicStarRating from 'components/common/DynamicStarRating';
import checkPrimaryLight from 'assets/img/animated-icons/check-primary-light.json';
import { getFromLocalStorage, saveToLocalStorage } from 'staticData/common';
import Lottie from 'lottie-react';

const FeedbackModal = ({ showModal, setShowModal }) => {
  const { userInfos } = useContext(AppContext);
  const [rating, setRating] = useState(0);
  const [questionID, setQuestionID] = useState(null);

  const feedbackQuestions_Sub = {
    satisfactionOverall:
      'Quanto sei soddisfatto dell’esperienza complessiva su Hodlie?',
    platformReliability:
      'Quanto trovi affidabile la piattaforma nel gestire il tuo portafoglio?',
    aiPortfolioEaseOfSelection:
      'Quanto hai trovato semplice la scelta del tuo AI Portfolio?',
    operationTransparency:
      'Quanto trovi trasparenti le operazioni che Hodlie esegue per te?',
    aiPortfolioDataAccessibility:
      'Quanto trovi chiari e accessibili i dati del tuo AI Portfolio?',
    customerSupportUsefulness:
      'Se sei entrato in contatto con il supporto clienti, quanto ti è stato utile?',
    assistanceSpeed:
      "Se sei entrato in contatto con il servizio di supporto, quanto l'hai trovato rapido?",
    supportCenterClarity:
      'Se hai utilizzato il centro di supporto, quanto sono chiare le guide disponibili?'
  };

  const feedbackQuestions_noSub = {
    intuitiveInterface: "Quanto trovi intuitiva l'interfaccia di Hodlie?",
    aiPortfolioExplanation:
      'Quanto trovi chiara la spiegazione degli AI Portfolio?',
    addedValueClarity:
      'Quanto è chiaro il valore aggiunto che Hodlie offre rispetto ad altre piattaforme?',
    riskLevelExplanation:
      'Quanto trovi chiara la spiegazione del livello di rischio?',
    historicalDataClarity:
      'Quanto trovi chiari i dati storici messi a disposizione?'
  };

  const feedbackQuestions =
    userInfos.pro_tier > 0
      ? { ...feedbackQuestions_Sub, ...feedbackQuestions_noSub }
      : feedbackQuestions_noSub;

  // Function to get a random feedback question
  const getRandomFeedbackQuestion = () => {
    const feedbackKeys = Object.keys(feedbackQuestions); // Get the keys of the feedback questions
    const storedFeedback = getFromLocalStorage('feedbackQuestions'); // Retrieve stored feedback from localStorage
    const now = new Date();

    var randomQuestionKey =
      feedbackKeys[Math.floor(Math.random() * feedbackKeys.length)]; // Select a random question key
    // If there are no saved questions in local storage, choose one randomly
    if (storedFeedback) {
      // If there are saved questions, check the saved keys
      const storedKeys = storedFeedback.list || []; // Get the stored keys or initialize as an empty array

      // Function to determine if two arrays are equal
      const areArraysEqual = (arr1, arr2) => {
        return (
          arr1.length === arr2.length && arr1.every(item => arr2.includes(item)) // Check if both arrays have the same length and the same items
        );
      };

      // Find a random key that is not already present in the saved list
      do {
        randomQuestionKey =
          feedbackKeys[Math.floor(Math.random() * feedbackKeys.length)]; // Select a random question key
      } while (
        storedKeys.includes(randomQuestionKey) && // Continue if the key is already stored
        !areArraysEqual(storedKeys, feedbackKeys) // And the stored keys are not equal to the feedback keys
      );

      // If the stored keys are not equal to feedback keys, save the new question key
      if (areArraysEqual(storedKeys, feedbackKeys)) {
        randomQuestionKey = null; // Return the selected question key
      }
    }
    saveToLocalStorage('feedbackQuestions', {
      ts: now,
      list: randomQuestionKey
        ? storedFeedback
          ? [...storedFeedback.list, randomQuestionKey]
          : [randomQuestionKey]
        : [...storedFeedback.list]
    });
    return randomQuestionKey;
  };

  const handleClick = newRating => {
    trackEvent('Modal Feedback vote', {
      user_email: userInfos.email,
      tracking_id: userInfos.tracking_id,
      questionID: questionID,
      question: feedbackQuestions[questionID],
      rating: newRating
    });
    setRating(newRating);
  };

  const handleClose = () => {
    if (rating === 0)
      trackEvent('Modal Feedback Close', {
        user_email: userInfos.email,
        tracking_id: userInfos.tracking_id,
        questionID: questionID,
        question: feedbackQuestions[questionID]
      });
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal) {
      let randomQuestion = getRandomFeedbackQuestion();
      if (randomQuestion) {
        setQuestionID(randomQuestion);
        trackEvent('Modal Feedback Show', {
          user_email: userInfos.email,
          tracking_id: userInfos.tracking_id,
          questionID: randomQuestion,
          question: feedbackQuestions[randomQuestion]
        });
      }
    }
  }, [showModal]);

  return (
    questionID && (
      <Modal
        show={showModal}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {rating === 0 ? (
          <>
            <Modal.Header closeButton closeVariant="white" className="">
              <Modal.Title
                id="contained-modal-title-vcenter text-center"
                style={{ width: '100%' }}
              >
                <Flex justifyContent="center">Aiutaci a migliorare!</Flex>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="mb-0 text-center">
                {questionID ? feedbackQuestions[questionID] : null}
              </p>
              <Flex justifyContent="center" className="mt-3">
                <DynamicStarRating
                  fractions={1}
                  rating={rating}
                  handleChange={e => handleClick(e)}
                  style={{ fontSize: '2rem' }}
                />
              </Flex>
              <br />
              <Flex justifyContent="center">
                <small className="text-center">
                  Dai un voto da 1 a 5 stelle
                </small>
              </Flex>
            </Modal.Body>
          </>
        ) : (
          <>
            <Modal.Header closeButton closeVariant="white" className="">
              <Modal.Title
                id="contained-modal-title-vcenter text-center"
                style={{ width: '100%' }}
              >
                <Flex justifyContent="center">Grazie per il tuo aiuto!</Flex>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className="justify-content-center mb-2">
                <Lottie
                  animationData={checkPrimaryLight}
                  loop={true}
                  style={{ height: '100px' }}
                />
              </Row>
              <p className="text-center">
                Il tuo feedback ci aiuterà a migliorare sempre di più!
              </p>
            </Modal.Body>
          </>
        )}
      </Modal>
    )
  );
};

FeedbackModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired
};

export default FeedbackModal;
